import { ColorsTheme } from 'constants/announces/ColorTheme'
import { FontSize, Indent, SizeViewport } from 'constants/sizes'
import { circeRegularFamily } from 'constants/fontsFamily'
import { styled } from 'linaria/react'

export const titleCss = `
  font-size: ${FontSize.base}px;
  line-height: 22px;
  font-weight: bold;
  margin: ${Indent.indent12}px 0;
`

export const Title = styled.h2`
  ${titleCss}
`

export const TitleStart = styled.h2`
  ${titleCss};
  margin: 0 0 ${Indent.indent16}px 0;
`

export const Text = styled.p`
  margin: 0 0 ${Indent.indent16}px 0;

  &:last-child {
    margin: 0;
  }
`

export const ListUnOrder = styled.ul`
  margin: 0 0 ${Indent.indent12}px ${Indent.indent40}px;
  padding: 0;

  li {
    margin: ${Indent.indent8}px;
  }
`

export const MainTitle = styled.h1`
  color: ${ColorsTheme.primaryText};
  font-size: ${FontSize.bigTitle}px;
  margin: ${Indent.indent32}px ${Indent.indent32}px 0 ${Indent.indent32}px;
  font-family: ${circeRegularFamily}, Arial, serif;
  font-weight: 700;

  @media screen and (max-width: ${SizeViewport.phone}px) {
    margin: ${Indent.indent20}px 0 0 0;
  }
`

export const ListWrapperBreadCrumb = styled.div`
  margin: 0 ${Indent.indent32}px;

  @media screen and (max-width: ${SizeViewport.phone}px) {
    margin: 0;
  }
`

export const ListTitle = styled.h4`
  margin: 0 0 15px 0;
  font-size: 18px;
`

export const List = styled.ul`
  margin: 0;
  padding: 0;

  li {
    margin: 5px 0;
    list-style-position: inside;
  }
`
