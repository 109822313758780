import { ColorsTheme } from 'constants/announces/ColorTheme'
import { Indent, SizeViewport } from 'constants/sizes'
import { Link } from 'react-router-dom'
import { circeRegularFamily } from 'constants/fontsFamily'
import { styled } from 'linaria/react'

interface BaseButtonProps {
  disabled?: boolean
  $loading?: boolean
}

const baseButtonCss = `
  display: inline-flex;
  border-radius: 8px;
  padding: ${Indent.indent12}px;
  font-size: 16px;  
  text-align: center;
  text-decoration: none;
  cursor: pointer;  
  transition: 0.2s;  
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  min-width: 100px;
  border: 1px solid transparent;
  
  @media screen and (max-width: ${SizeViewport.phone}px){
    display: flex;
  }
  
  font-family: ${circeRegularFamily}, Arial, serif;
`

const secondaryButtonCss = `
  border-width: 1px;
  border-style: solid;  
`

const disabledCss = `
  &:disabled {
    opacity: .5;    
  }
`

export const PrimaryLink = styled(Link)<BaseButtonProps>`
  ${baseButtonCss};

  color: ${ColorsTheme.white};
  background-color: ${ColorsTheme.primaryDefault};

  &:hover {
    background-color: ${ColorsTheme.primaryHover};
  }

  &:focus {
    background-color: ${ColorsTheme.primaryHover};
    box-shadow: 0 0 0 1px ${ColorsTheme.primaryDefaultLight};
  }

  &:active {
    background-color: ${ColorsTheme.primaryActive};
  }

  &[disabled] {
    opacity: 0.5;
    color: ${ColorsTheme.primaryDefaultLight};
    border-color: ${ColorsTheme.primaryDefaultLight};

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      color: ${ColorsTheme.primaryDefaultLight};
      border-color: ${ColorsTheme.primaryDefaultLight};
    }
  }
`

export const PrimaryInput = styled.input<BaseButtonProps>`
  ${baseButtonCss}
  ${disabledCss}

  color: ${(props) =>
    props.$loading ? 'rgba(255, 255, 255, 0)' : ColorsTheme.white};
  background-color: ${ColorsTheme.primaryDefault};

  &:hover:enabled {
    background-color: ${ColorsTheme.primaryHover};
  }

  &:focus:enabled {
    background-color: ${ColorsTheme.primaryHover};
    box-shadow: 0 0 0 1px ${ColorsTheme.primaryDefaultLight};
  }

  &:active:enabled {
    background-color: ${ColorsTheme.primaryActive};
  }

  &:disabled {
    border-color: ${ColorsTheme.primaryDefaultLight};
    color: ${ColorsTheme.primaryDefaultLight};
  }
`

export const PrimaryButton = styled.button<BaseButtonProps>`
  ${baseButtonCss}
  ${disabledCss}

  color: ${ColorsTheme.white};
  background-color: ${ColorsTheme.primaryDefault};

  &:hover:enabled {
    background-color: ${ColorsTheme.primaryHover};
  }

  &:focus:enabled {
    background-color: ${ColorsTheme.primaryHover};
    box-shadow: 0 0 0 1px ${ColorsTheme.primaryDefaultLight};
  }

  &:active:enabled {
    background-color: ${ColorsTheme.primaryActive};
  }

  &:disabled {
    border-color: ${ColorsTheme.primaryDefaultLight};
    color: ${ColorsTheme.primaryDefaultLight};
  }
`

export const SecondaryLink = styled(Link)<BaseButtonProps>`
  ${baseButtonCss}
  ${secondaryButtonCss}

  background-color: transparent;

  color: ${ColorsTheme.primaryDefault};
  border-color: ${ColorsTheme.primaryDefault};

  &:hover {
    border-color: ${ColorsTheme.primaryHover};
    color: ${ColorsTheme.primaryHover};
  }

  &:focus {
    border-color: ${ColorsTheme.primaryHover};
    box-shadow: 0 0 0 1px ${ColorsTheme.primaryDefaultLight};
    color: ${ColorsTheme.primaryHover};
  }

  &:active {
    border-color: ${ColorsTheme.primaryActive};
    color: ${ColorsTheme.primaryActive};
  }

  &[disabled] {
    opacity: 0.5;
    color: ${ColorsTheme.primaryDefaultLight};
    border-color: ${ColorsTheme.primaryDefaultLight};

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      color: ${ColorsTheme.primaryDefaultLight};
      border-color: ${ColorsTheme.primaryDefaultLight};
    }
  }
`

export const SecondaryInput = styled.input<BaseButtonProps>`
  ${baseButtonCss}
  ${disabledCss}
  ${secondaryButtonCss}
  
  background-color: transparent;

  color: ${ColorsTheme.primaryDefault};
  border-color: ${ColorsTheme.primaryDefault};

  &:hover:enabled {
    border-color: ${ColorsTheme.primaryHover};
    color: ${ColorsTheme.primaryHover};
  }

  &:focus:enabled {
    border-color: ${ColorsTheme.primaryHover};
    box-shadow: 0 0 0 1px ${ColorsTheme.primaryDefaultLight};
    color: ${ColorsTheme.primaryHover};
  }

  &:active:enabled {
    border-color: ${ColorsTheme.primaryActive};
    color: ${ColorsTheme.primaryActive};
  }

  &:disabled {
    border-color: ${ColorsTheme.primaryDefaultLight};
    color: ${ColorsTheme.primaryDefaultLight};
  }
`

export const SecondaryButton = styled.button<BaseButtonProps>`
  ${baseButtonCss}
  ${disabledCss}
  ${secondaryButtonCss}

  background-color: transparent;

  color: ${ColorsTheme.primaryDefault};
  border-color: ${ColorsTheme.primaryDefault};

  &:hover:enabled {
    border-color: ${ColorsTheme.primaryHover};
    color: ${ColorsTheme.primaryHover};
  }

  &:focus:enabled {
    border-color: ${ColorsTheme.primaryHover};
    box-shadow: 0 0 0 1px ${ColorsTheme.primaryDefaultLight};
    color: ${ColorsTheme.primaryHover};
  }

  &:active:enabled {
    border-color: ${ColorsTheme.primaryActive};
    color: ${ColorsTheme.primaryActive};
  }

  &:disabled {
    border-color: ${ColorsTheme.primaryDefaultLight};
    color: ${ColorsTheme.primaryDefaultLight};
  }
`
