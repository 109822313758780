import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/loader/DefaultLoader'

export const NoticeEmailInvalidLoadable = loadable(
  () => import('./NoticeEmailInvalid'),
  {
    fallback: <DefaultLoader />,
  }
)
