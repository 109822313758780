export const smallPadding = 10

export const FontSize = {
  bigTitle: 26,
  base: 18,
  noteAutoComplete: 16,
  smallLabel: 12,
  priceTitle: 21,
  breadCrumbs: 15,
  code: 36,
}

export const Indent = {
  indent40: 40,
  indent32: 32,
  indent20: 20,
  indent16: 16,
  indent18: 18,
  indent12: 12,
  indent8: 8,
  indent4: 4,
}

export const SizeViewport = {
  smallPhone: 320,
  middlePhone: 395,
  phone: 600,
  tablet: 800,
  desktop: 1024,
  fullDesktop: 1240,
}
