export interface AnnounceParams {
  announceId: string
  answerId: string
}

export interface AnnouncePaymentParams extends AnnounceParams {
  paymentType: string
}

export interface AnnounceListParams {
  whoIsSeeker: 'm' | 'f'
  whoLookingFor: 'm' | 'f' | 'p'
  minAge: string
  maxAge: string
  datingGoal: string
  location: string
  tags: string
  page: string
}

export interface AuthorizationParams {
  service: string
  announceId?: string
}

export interface ShowcasePackageParams {
  orderId: string
  productId: string
}

/**
 * Страницы без основного layout.
 */
export const plainPage = 'plain'

/**
 * В enum нельзя вставить вычисляемые значения.
 */
const whoLookingForAndSeeker = '/:whoLookingFor(m|f|p)-:whoIsSeeker(m|f|p)'
const announceListBasePath = `/list${whoLookingForAndSeeker}/:minAge(\\d+)-:maxAge(\\d+)/:datingGoal(\\d+)`

export const announceListLocation = '/:location(\\d+-\\d+-\\d+-\\d+)'
const announceListRedirectLocation = '/:location(\\d+-\\d+-\\d+)'

export const announceListPath = `${announceListBasePath}${announceListLocation}/:page(\\d+)?`
export const announcesLocationRedirectPath = `${announceListBasePath}${announceListRedirectLocation}`

const announceAdsBasePath = `/list${whoLookingForAndSeeker}/:minAge(\\d+)-:maxAge(\\d+)`
export const announceAdsTagsPath = `${announceAdsBasePath}/:tags`

export const announceAdsListPath = `${announceAdsTagsPath}${announceListLocation}/:page(\\d+)?`

export enum Paths {
  index = '/',
  baseList = '/list',
  list = '/list/whoLookingFor:-:whoIsSeeker/:minAge-:maxAge/:datingGoal/:location', // TODO: а нужен
  announcePart = '/id',
  announceFull = '/id:announceId(\\d+)',
  announceSeoRedirect = '/id:announceId',
  announceDelete = '/list/delete',
  announceForm = '/settings',
  announceSubscribeManagement = '/subscription_management', // надо сохранить url старые, которые в php.
  announceModeration = '/list/moderation',
  announceCreateStart = '/announce/add/step-1',
  announceCreateFinish = '/announce/add/step-2',
  agreement = '/agreement',
  policy = '/policy',
  announceFormLocation = '/find-location-autocomplete',
  notFound = '/not-found',
  // TODO добавить страницу ошибки сети
  networkError = '/network-error',
  showCaseVipPart = '/showcase/vip/',
  showCaseAnswerPart = '/showcase/answer/',
  showCaseVip = '/showcase/vip/id:announceId/:paymentType?',
  showCaseAnswer = '/showcase/answer/id:answerId/:paymentType?',
  paymentAnswerSubscribe = '/payment-answer-subscribe',
  redirectSupportForm = '/feedback',
  bbsChat = '/bbs/chat',
  agreementPlain = '/agreement/plain',
  policyPlain = '/policy/plain',
  authorizeWithEmailPart = '/confirm-email',
  authorizeWithEmail = '/confirm-email/:service?/:announceId?',
  createAnnounceFormRestore = '/announce/add/restore',
  announceAnswerPostponePart = '/announce/answer/postpone',
  announceAnswerPostpone = '/announce/answer/postpone/:announceId',
  showcasePremiumPart = '/showcase/package/orderId',
  showcasePremiumOrderId = ':orderId',
  showcaseProductId = ':productId',

  /**
   * Отдельная страницы оплаты для приложение для пользователей с ios
   * https://youtrack.mamba.ru/issue/MSKD-860
   */
  externalPayment = '/ios-payment',
  /*  Отдельная страница лендинга для навигации трафика между приложениями и сайтом */
  trafficLanding = '/fork',
}
