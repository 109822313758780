import React, { FC } from 'react'
import { LogoLink } from 'components/presentational/logo/Logo.styled'
import { LogoSvg } from 'components/presentational/logo/icons/LogoSvg'
import { WithClassName } from 'common/types'
import { useRedirectLink } from 'hooks/useRedirectLink'

export const Logo: FC<WithClassName> = ({ className }) => {
  const redirectUrl = useRedirectLink()

  return (
    <LogoLink
      to={redirectUrl as string}
      style={{ flexGrow: 1 }}
      data-name="logo-action"
      className={className}
    >
      <LogoSvg />
    </LogoLink>
  )
}
