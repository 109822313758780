import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/loader/DefaultLoader'

export interface PaySuccessParams {
  typesOrder: string
}

export const PaySuccessLoadable = loadable(() => import('./PaySuccess'), {
  fallback: <DefaultLoader />,
})
