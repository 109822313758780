import React, { FC } from 'react'
import { ButtonLoader } from 'components/presentational/loader/ButtonLoader'
import { ColorsTheme } from 'constants/announces/ColorTheme'
import { PrimaryButton, PrimaryInput, PrimaryLink } from './button.styled'
import { WithDataName } from 'common/types'
import { styled } from 'linaria/react'
import { useFormData } from 'components/presentational/form/form.hooks'

/**
 * Нигде не используется
 */
export const PrimaryLinkButton: FC<
  {
    to: string
    replace?: boolean
    disabled?: boolean
    $loading?: boolean
  } & WithDataName
> = ({
  to,
  replace = false,
  disabled = false,
  $loading = false,
  children,
  ...rest
}) => {
  return (
    <PrimaryLink
      to={to}
      replace={replace}
      data-name={rest['data-name']}
      disabled={disabled}
      $loading={$loading}
    >
      {children}
    </PrimaryLink>
  )
}

export const PrimaryClickButton: FC<
  {
    onClick: () => void
    disabled?: boolean
    $loading?: boolean
  } & WithDataName
> = ({ onClick, children, disabled = false, $loading = false, ...rest }) => {
  return (
    <PrimaryButton
      onClick={onClick}
      data-name={rest['data-name']}
      disabled={disabled}
      $loading={$loading}
    >
      {children}
    </PrimaryButton>
  )
}

export const PrimaryInputButton: FC<
  {
    type: string
    value: string
    disabled?: boolean
    $loading?: boolean
  } & WithDataName
> = ({ type, value, disabled = false, ...rest }) => {
  const { loading } = useFormData()

  const component = (
    <PrimaryInput
      type={type}
      data-name={rest['data-name']}
      disabled={disabled}
      value={value}
      $loading={loading}
    />
  )

  if (loading) {
    return (
      <LoaderWrapper>
        {component} <ButtonLoader size={36} colorFill={ColorsTheme.white} />
      </LoaderWrapper>
    )
  }

  return component
}

export const LoaderWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;
`
